import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, createSlots as _createSlots } from "vue"

const _hoisted_1 = { class: "teaser-carousel md:relative" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StoryBarAutoPlay = _resolveComponent("StoryBarAutoPlay")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_TeaserCard = _resolveComponent("TeaserCard")!
  const _component_VueperSlide = _resolveComponent("VueperSlide")!
  const _component_Carousel = _resolveComponent("Carousel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.innerSlides.length > 1)
      ? (_openBlock(), _createBlock(_component_StoryBarAutoPlay, {
          key: 0,
          ref: "storyBarAutoPlayRef",
          items: _ctx.innerSlides,
          timelineProps: {
        autoplay: true,
        loop: true,
        duration: 10000,
      },
          onChangeStoryBar: _ctx.onChangeSlide
        }, null, 8, ["items", "onChangeStoryBar"]))
      : _createCommentVNode("", true),
    (!_ctx.isDesktop)
      ? (_openBlock(), _createElementBlock("a", {
          key: 1,
          class: _normalizeClass(["teaser-carousel__close absolute right-3.5 top-4 z-50", { hidden: _ctx.innerSlides?.some((teaser) => teaser?.isArchived) }]),
          href: "https://www.iqos.com/de/de/loyalty.html",
          rel: "noopener noreferrer"
        }, [
          _createVNode(_component_Icon, {
            type: "close",
            theme: "light",
            class: "w-4 h-3.5 pointer-events-none"
          })
        ], 2))
      : _createCommentVNode("", true),
    (_ctx.totalItems)
      ? (_openBlock(), _createBlock(_component_Carousel, {
          key: 2,
          ref: "carouselRef",
          class: "px-0",
          bullets: false,
          arrows: !_ctx.isMobile,
          disable: _ctx.sliderDisabled,
          preventYScroll: _ctx.preventYScrollTeaser,
          leftArrowIcon: 
        require('@/assets/svgs/button-arrow-left-light-theme.svg')
      ,
          rightArrowIcon: 
        require('@/assets/svgs/button-arrow-right-light-theme.svg')
      ,
          breakpoints: _ctx.carouselBreakpointsConfig,
          onSlide: _ctx.onNavigateStoryBar,
          onMousedown: _cache[2] || (_cache[2] = (event) => _ctx.onInteractStoryBar(event, true)),
          onMouseup: _cache[3] || (_cache[3] = (event) => _ctx.onInteractStoryBar(event, false)),
          onTouchstart: _cache[4] || (_cache[4] = (event) => _ctx.onInteractStoryBar(event, true)),
          onTouchend: _cache[5] || (_cache[5] = (event) => _ctx.onInteractStoryBar(event, false))
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.innerSlides, (teaser, index) => {
              return (_openBlock(), _createBlock(_component_VueperSlide, {
                key: teaser?.page_teaser[0]?.id || `carousel-teaser-${index}`
              }, _createSlots({ _: 2 }, [
                (teaser?.page_teaser[0]?.id)
                  ? {
                      name: "content",
                      fn: _withCtx(() => [
                        _createVNode(_component_TeaserCard, {
                          class: "teaser-carousel__teaser-card w-full",
                          articlePageId: teaser?.id,
                          articlePageSlug: teaser?.meta?.slug,
                          category: teaser?.category,
                          publicationDate: teaser?.publication_date,
                          iqosPoints: teaser?.iqos_points,
                          iqosPointsOnly: teaser?.iqos_points_only,
                          statusPointsOnly: teaser?.status_points_only,
                          statusPoints: teaser?.status_points,
                          teaser: teaser?.page_teaser[0],
                          storyContent: teaser?.page_content,
                          storyCTAs: teaser?.page_cta,
                          footnotes: teaser?.page_footnote,
                          isArchived: teaser?.isArchived,
                          onPauseStoryBar: _cache[0] || (_cache[0] = (value) => _ctx.onInteractStoryBar(null, value)),
                          onPlayStoryBar: _cache[1] || (_cache[1] = (value) => _ctx.onInteractStoryBar(null, value))
                        }, null, 8, ["articlePageId", "articlePageSlug", "category", "publicationDate", "iqosPoints", "iqosPointsOnly", "statusPointsOnly", "statusPoints", "teaser", "storyContent", "storyCTAs", "footnotes", "isArchived"])
                      ]),
                      key: "0"
                    }
                  : undefined
              ]), 1024))
            }), 128)),
            (_ctx.isLastStoryRead)
              ? (_openBlock(), _createBlock(_component_VueperSlide, { key: 0 }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["arrows", "disable", "preventYScroll", "leftArrowIcon", "rightArrowIcon", "breakpoints", "onSlide"]))
      : _createCommentVNode("", true)
  ]))
}