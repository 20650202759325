
import {
  defineComponent,
  PropType,
  reactive,
  ref,
  onMounted,
  computed,
} from 'vue';
import { useRouter } from 'vue-router';
import { format } from 'date-fns';
import { PointTypesEnum } from '@/types';

import useBreakpoints from '@/use/useBreakpoints';

import {
  pushDataLayer,
  storyInteractionField,
} from '@/utils/GTMCustomDataLayers/stories';
import { StoryInteractionField } from '@/utils/GTMCustomDataLayers/types';

import Button from '@/components/Button/Button.vue';
import Badge from '@/components/Badge/Badge.vue';
import Icon from '@/components/Icon/Icon.vue';
import StoriesContentModal from '../StoriesContentModal/StoriesContentModal.vue';
import StoriesModulesContent from '../StoriesModulesContent/StoriesModulesContent.vue';
import { Teaser, PageContent, PageCta, Footnote } from '../../types';

import { routeNames } from '../../router';
import ResponsiveImage from '@/components/RImageSrcset/RImageSrcset.vue';

const PAUSE_STORY_BAR = 'pauseStoryBar';
const PLAY_STORY_BAR = 'playStoryBar';

const hideElementsOnMobile = (value, elementsClasses) => {
  elementsClasses.forEach((elementClass) => {
    const element = document.querySelector(elementClass);
    element?.classList[value ? 'add' : 'remove']('hidden');
  });
};

export default defineComponent({
  components: {
    ResponsiveImage,
    Button,
    StoriesContentModal,
    StoriesModulesContent,
    Badge,
    Icon,
  },
  props: {
    articlePageId: {
      type: Number,
      default: null,
    },
    articlePageSlug: {
      type: String,
      default: null,
    },
    category: {
      type: String,
      default: null,
    },
    publicationDate: {
      type: String,
      default: null,
    },
    iqosPoints: {
      type: Number,
      default: null,
    },
    statusPoints: {
      type: Number,
      default: null,
    },
    statusPointsOnly: {
      type: Boolean,
      default: false,
    },
    iqosPointsOnly: {
      type: Boolean,
      default: false,
    },
    teaser: {
      type: Object as PropType<Teaser>,
      default: null,
    },
    storyContent: {
      type: Array as PropType<PageContent>,
      default: null,
    },
    storyCTAs: {
      type: Array as PropType<PageCta>,
      default: null,
    },
    footnotes: {
      type: Array as PropType<Footnote[]>,
      default: () => [],
    },
    isArchived: {
      type: Boolean,
      default: null,
    },
  },
  emits: [PAUSE_STORY_BAR, PLAY_STORY_BAR],
  setup(props, { emit }) {
    const { push } = useRouter();
    const { isMd, isLg } = reactive(useBreakpoints());
    const isScreenMd = isMd();
    const isScreenLg = isLg();

    const storiesContentModal = ref(false);

    onMounted(() => {
      // Open modal directly if it's an archived story
      if (props.isArchived) onClickStoriesModal(true);
    });

    const prepareAndSendGTMObject = (title, text, url) => {
      const storyGTMObject: StoryInteractionField = {
        ...storyInteractionField,
        storyTitle: title,
        clickText: text,
        clickURL: url,
      };
      pushDataLayer('clickEvent', { storyInteractions: storyGTMObject });
    };

    const handleArchivedStoryClose = () => {
      setTimeout(() => {
        push(routeNames.STORIES_ARCHIVE);
      }, 800);
    };

    const handleRegularStoryClose = () => {
      setTimeout(() => {
        push(routeNames.STORIES_HOME);
      }, 800);
    };

    const onClickStoriesModal = (value) => {
      emit(value ? PAUSE_STORY_BAR : PLAY_STORY_BAR, value);

      // When opening the modal
      if (value) {
        prepareAndSendGTMObject(
          props.teaser?.value?.header,
          props.teaser?.value?.cta_name,
          `https://www.iqos.com/stories/${props.articlePageSlug}`
        );
      }

      // When closing modal
      if (!value) {
        props.isArchived
          ? handleArchivedStoryClose()
          : handleRegularStoryClose();
      }

      storiesContentModal.value = value;
      if (!isScreenMd) {
        hideElementsOnMobile(value, [
          '.footer',
          '.stories-home__logo',
          '.story-bar',
          '.teaser-carousel__close',
          '.health-warning',
        ]);
      }
    };

    const onSwipeTeaser = () => {
      !isScreenLg && onClickStoriesModal(true);
    };

    const formattedPublicationDate = computed(
      () =>
        props.publicationDate &&
        format(new Date(props.publicationDate), 'dd/MM/yyyy')
    );

    return {
      isScreenMd,
      isScreenLg,
      storiesContentModal,
      onClickStoriesModal,
      onSwipeTeaser,
      prepareAndSendGTMObject,
      formattedPublicationDate,
      PointTypesEnum,
    };
  },
});
